.main-header {
  color: var(--default-color);
  padding: 10px 50px 10px 50px;
  font-size: 16px;
  font-weight: var(--font-weight-semi-bold);
  line-height: 19px;
  box-shadow: 0 0px 10px -2px var(--default-color);
  width: 100%;
  position: fixed;
  left: 0;
  // background: #ffffff;
  background: #1f2937; // v2
  z-index: 3;

  .amplify-button {
    padding: 5px;
    border: none;
    &:hover,
    &:focus {
      background: none;
      border: none;
      box-shadow: 0 0 0 0;
    }
    &.notif-button {
      border-radius: 50%;
    }
  }

  .amplify-button--menu {
    font-weight: 300;
    &:hover {
      background: rgba(33, 150, 243, 0.08);
      color: #000;
    }
  }
}

.header-right-menu {
  align-items: center;
  margin-left: auto;
  padding-left: 30px;
  position: relative;
  &::before {
    content: url("../../public/images/border.svg");
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
  }

  .custom-button {
    min-width: auto;
  }
}

.header-title {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  padding: 10px 0;
  color: #ffff;
}

.account-icon {
  cursor: pointer;
  align-items: center;
  margin-left: 25px;
}

.account-menu {
  margin-top: 22px;
}

@media only screen and (max-width: 767px) {
  .main-header {
    display: none;
    padding: 5px 15px 5px 15px;
  }

  .account-menu {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.header-username {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  color: #dce4e8;
}

.help-button {
  margin-left: auto;
}

@media print {
  .main-banner {
    background: none !important;
    min-height: auto;
    .amplify-heading {
      color: #000;
    }
    .amplify-breadcrumbs {
      display: none;
    }
  }
}
