.amplify-link {
  &:visited {
    color: inherit;
  }
}

.main-content,
.custom-table {
  // margin-top: 20px;
  // padding: 30px;
  background-color: #ffffff;
  @media (max-width: 767px) {
    box-shadow: 0px 0px 0px 0px;
    border-radius: 0;
  }
  .main-container {
    padding: 40px 70px;
    @media (max-width: 767px) {
      padding: 40px 20px;
    }
  }
}

.custom-table {
  .amplify-table__th {
    padding: 12px 16px;
  }
  .amplify-table__td {
    padding: 12px 16px;
  }
  .amplify-table__th,
  .amplify-table__td {
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;
    &:first-child {
      border-left-width: 0;
    }
    &:last-child {
      border-right-width: 0;
    }
  }
}

.custom-table-2 {
  .amplify-table__th {
    padding: 0 16px;
    min-height: 35px;
    background-color: #EDF0F888;
    border-left: none;
    border-right: none;
    color: #0d1926;
    font-size: 1rem;
    font-weight: 700;
  }
  .amplify-table__td {
    font-size: 1rem;
    color: #0d1926;
    padding: 5.5px 16px;
    font-weight: 400;
  }
  .amplify-table__row {
    &:nth-child(2) {
      // .amplify-table__td {
      //   border-top-width: 0;
      // }
    }
    .table-title {
      display: none;
    }
  }
}

.table-actions-v2 {
  .amplify-button.custom-button {
    min-width: unset;
  }
}

@media only screen and (max-width: 768px) {
  .custom-table-2 {
    .amplify-table__row {
      position: relative;
      border-top: 1px solid #0000001f;
      &:last-of-type {
        border-bottom: 1px solid #0000001f;
      }
    }
    .amplify-table__td {
      display: block;
      border: 0;
      padding: 0 10px 5px 0;
      width: 100% !important;
      &:first-of-type {
        padding-top: 10px;
      }
      &:nth-of-type(5) {
        padding-bottom: 10px;
      }
      &:nth-of-type(6),
      &:nth-of-type(7) {
        display: none;
      }
      .table-title,
      &::before {
        content: attr(data-title);
        display: inline-block;
        min-width: 110px;
        text-align: center;
        background: #EDF0F8;
        margin-right: 10px;
      }
      .table-title {
        display: block;
      }
      &.table-actions-v2 {
        display: block;
        position: absolute;
        right: 10px;
        top: 40%;
        transform: translateY(-50%);
        right: 0;
        width: 70px !important;
        .custom-button {
          min-width: 20px !important;
        }
        &.auth-actions {
          width: 56px !important;
          padding: 0;
          top: 20px;
          right: 0;

          .custom-button {
            padding-right: 0;
          }
        }

        &.user-list-tb {
          top: 27px;
          right: -15px;
        }
      }
    }

    .table-no-title {
      .amplify-table__td {
        display: flex;
      }
      .amplify-table__td::before {
        display: none !important;
      }
    }
  }
  .amplify-flex.hide-from-print-v2 {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .custom-table-head {
    display: none !important;
  }
}

.custom-select {
  &.amplify-selectfield {
    font-size: 12px;
    flex-direction: row !important;
  }
  .amplify-select--quiet {
    font-size: 12px;
    border: 0;
    padding: 0;
    min-width: 50px;
  }
}

.custom-pagination {
  li:not(:first-child):not(:last-child) {
    display: none;
  }
  &.disabled li:last-child,
  .amplify-button--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.firstPage li:first-child,
  .amplify-button--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.amplify-button {
  background-color: #043495;
  &:hover {
    opacity: 0.8;
    transition: 0.2s linear;
  }
}

.custom-form {
  .form-note {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    letter-spacing: -0.02em;
    color: #6c7278;
  }
  .header-required::after {
    content: "＊";
    color: #2196f3;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.02em;
    position: relative;
    left: 3px;
  }

  .form-required-note {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    letter-spacing: -0.02em;
    text-align: right;
    color: #6c7278;
    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 16.39px;
      letter-spacing: -0.02em;
      text-align: right;
      color: #2196f3;
    }
  }
  .amplify-field {
    position: relative;
    margin-bottom: 35px;
    width: 100%;
    &.required {
      .amplify-label {
        padding: 0 20px 0 5px;
      }
      .amplify-label,
      .amplify-checkbox__label {
        &::after {
          content: "＊";
          color: #2196f3;
          font-size: 12px;
          font-weight: 300;
          line-height: 19.2px;
          letter-spacing: -0.02em;
          position: absolute;
          right: 5px;
        }
      }
      .amplify-checkbox__label::after {
        right: -15px;
      }
    }

    &.user-checkbox {
      margin-bottom: 15px;
      .amplify-text {
        color: #0d1926;
      }
    }
  }
  .amplify-label {
    position: absolute;
    top: -10px;
    left: 13px;
    padding: 0 5px;
    background: #ffffff;
    z-index: 1;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 19.2px;
    letter-spacing: -0.02em;
    color: #acb5bb;
  }
  .react-custom-select > div,
  .amplify-input,
  .amplify-select,
  .amplify-textarea {
    padding: 15px 14px;
    border-color: #dce4e8;
    border-radius: 10px;
    &:disabled {
      border-color: rgba(0, 0, 0, 0.23);
    }
    &:focus {
      border-color: #2196f3;
      box-shadow: 0 0 0 1px #2196f3;
    }
  }
  .amplify-textarea {
    min-height: 153px;
  }
  .amplify-textareafield {
    margin-bottom: 5px;
    & ~ .textarea-length {
      padding-right: 14px;
      font-size: 12px;
      text-align: right;
      display: block;
    }
  }
  .input-error {
    .amplify-input,
    .amplify-select {
      border-color: red;
      &:focus {
        box-shadow: 0px 0px 0px 2px red;
      }
    }
  }
  .amplify-checkbox__button--error::before {
    border-color: red;
  }
}

.table-v2 {
  padding: 0;
  margin-top: 0 !important;
  .amplify-table__td {
    padding: 0;
  }
  .table-title {
    font-size: 20px;
    color: #304050;
    margin-bottom: 30px;
  }
  .custom-fieldset {
    border: 0;
    border-top: 1px solid #0000001f;
    padding: 15px 15px 20px;
    legend {
      padding: 0 10px;
      color: #ACB5BB;
      font-size: 12px;
    }
    .amplify-text {
      font-size: 14px;
      color: #6C7278;
      padding: 0 10px;
    }
    &:last-of-type {
      border-bottom: 1px solid #0000001f;
    }
  }
}

.react-custom-select > .css-1nmdiq5-menu {
  padding: 8px 14px !important;
  z-index: 2;
}

.amplify-checkbox__icon--checked {
  background-color: #2196f3 !important;
}

.table-loader {
  width: 100%;
  text-align: center;
}

.amplify-accordion__item {
  border-radius: 0 !important;
  border-top: 1px solid #dcdcdc !important;
  &:hover {
    background-color: #fff !important;
  }
}

.amplify-accordion__item__content {
  border-top: 2px solid #dcdcdc !important;
  background-color: #fafafa !important;
}

.amplify-checkbox__icon--checked {
  background-color: #2196f3 !important;
}

.accordion-arrow {
  transform: rotate(180deg);
}

.input-with-note {
  margin-bottom: 7px !important;
  & ~ .input-note {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    letter-spacing: -0.02em;
    color: #acb5bb;
    margin-bottom: 35px;
  }
}

.amplify-breadcrumbs {
  .amplify-link:visited,
  .amplify-text {
    color: #ffffff;
  }
}

.amplify-pagination {
  li {
    &:first-child {
      margin-right: 20px;
    }
  }
}

.amplify-pagination__item {
  border: 1px solid #6c7278 !important;
  border-radius: 12px !important;
  width: 48px !important;
  height: 48px !important;
}

.form-divider {
  margin: 35px 0 !important;
}

.site-checkboxes {
  flex-wrap: wrap !important;
  gap: 1rem !important;
  justify-content: center;
  margin: 20px 0 40px !important;
  & > .amplify-flex {
    flex-basis: 30%;
  }

  .amplify-checkboxfield {
    margin: 0 !important;
  }
}

// .amplify-searchfield {
//   .amplify-button {
//     pointer-events: none;
//   }
// }

.control-list-modal {
  .modal-content > .amplify-text {
    display: none;
  }
}

.control-list-container {
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;

  .control-check {
    flex-basis: 50%;
    flex-grow: 1;
    gap: 20px;
  }
}