.main-body {
  height: calc(100vh - -14rem);
  .main-footer {
    position: relative;

    // right: 30rem;
    text-align: start;
    background-color: transparent;
  }
}
.main-grid {
  padding: 100px 100px 0px 100px;
  background-color: #f3f6fc;
  margin: -40px -70px;
  width: calc(100% + 139px);
  height: calc(100vh - -14rem);
}

.icon-button {
  svg {
    width: 6px !important;
    path {
      fill: white !important;
    }
  }
  background-color: #5ab6b0 !important;
  max-width: 20px !important;
  max-height: 20px !important;
}
.top-modal {
  .custom-button {
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 0px;
  }
}

.dot {
  cursor: pointer;
  height: 5px;
  width: 30px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 10%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.notification-card-container {
  background-color: #ffff;
  width: 100%;
  margin: 0;
  padding: 0 0 10px;
  min-height: 200px;
  position: relative;
  z-index: 1;
  .notification-card {
    gap: 10px;
    padding-bottom: 5px;
  }
  .carousel-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;

    .notification-card-item {
      min-width: 310px;
      transition: opacity 0.5s ease-in-out;
      flex-grow: 1;
      &.active {
        opacity: 1;
      }
    }
  }

  .carousel {
    display: none;
    justify-content: center;
    margin-top: 10px;

    .dot {
      cursor: pointer;
      height: 5px;
      width: 30px;
      margin: 0 2px;
      background-color: #bbb;
      border-radius: 25%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .dot.active {
      background-color: #4365de;
      border-radius: 25%;
    }
  }

  .dot {
    height: 5px;
    width: 30px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 10%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .dot.active {
    background-color: #717171;
  }

  button.prev,
  button.next {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  button.prev {
    left: 10px;
  }

  button.next {
    right: 10px;
  }

  .amplify-heading--6 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 285px;
  }
}
.notification-card-item.amplify-card {
  min-width: 100%;
}
.navigation-main-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 20px;
  .navigation-card-main-container {
    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    // gap: 10;
    margin-top: 30px;
    .navigation-card-container {
      // flex-basis: calc(25% - 5px);
      // flex-grow: 1;
      width: 100%;
      .header-text {
        cursor: pointer;

        border-left: 4px solid #5ab6b0;
        padding: 0px 20px;
        max-height: inherit;
        //min-width: 38rem;
      }
      .navigation-card-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; // Optional: adjust as needed for spacing between items
        .nav-item {
          cursor: pointer;

          flex-basis: calc(
            50% - 5px
          ); // Adjust the subtraction value to account for the gap
          flex-grow: 1;
          text-align: left; // Optional: for centering text
          padding: 10px; // Optional: adjust as needed
          box-sizing: border-box; // Ensures padding and border are included in the width calculation
          .custom-button {
            min-width: 20px !important;
            min-height: 20px !important;
            padding: 0px;
          }
        }
      }
    }
  }
  .header-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    // margin-top: 20px;

    .header-card-container {
      flex-basis: calc(30% - 5px);
      flex-grow: 1;

      .header-item {
        cursor: pointer;
        // min-height: 100px;
        // display: flex;
        flex-direction: row;
        flex-basis: calc(
          30% - 5px
        ); // Adjust the subtraction value to account for the gap
        flex-grow: 1;
        text-align: center; // Optional: for centering text
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;

        .header-card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .header-text {
          border-left: 4px solid #5ab6b0;
          padding: 0px 20px;
          max-height: inherit;
          cursor: pointer;
        }

        .icon-button {
          width: 50px !important;

          svg {
            width: 50px !important;

            path {
              opacity: 1 !important;
              fill: black !important;
            }
          }

          background-color: transparent !important;
          max-width: 20px !important;
          max-height: 20px !important;
        }
        .custom-button {
          min-width: 50px !important;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .header-container {
    flex-direction: column;
    min-width: inherit;
  }
  .header-card-container {
    flex-basis: 100%; // Adjust to show two columns on a MacBook 13-inch screen
    min-width: inherit;
  }
  .notification-card-item.amplify-card {
    max-width: inherit !important;
  }
}

@media (max-width: 1024px) {
  .header-container {
    flex-direction: column;
  }
  .header-card-container {
    flex-basis: inherit; // Adjust to show two columns on smaller screens like iPads
    position: relative;
    margin-left: 0;
    left: 0;
    .notification-card-item.amplify-card {
      max-width: inherit !important;
    }
    .amplify-heading--2 {
      font-size: 2em; // h6 font-size
      font-weight: bold; // h6 font-weight
      // Add other styles for h6 here
    }
  }
}

@media (max-width: 1030px) {
  .navigation-main-wrapper {
    left: 0;
    .navigation-card-main-container {
      flex-direction: column;
      .navigation-card-container  {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
  }

  // .header-card-container {
  //   flex-basis: 100%;

  //   .header-item {
  //     flex-direction: column;
  //     text-align: center; // Optional: for centering text
  //     padding: 0px 10px; // Optional: adjust as needed
  //     .header-card {
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;
  //       align-items: center;
  //       min-height: 150px;
  //       min-width: calc(100% + 20px);
  //       right: 30px;
  //       position: relative;
  //     }
  //   }
  //   .amplify-heading--2 {
  //     font-size: 1em; // h6 font-size
  //     font-weight: bold; // h6 font-weight
  //     // Add other styles for h6 here
  //   }
  // }
  .notification-card-item.amplify-card {
    max-width: inherit !important;
    position: relative;
    right: 20px;
    // padding: 10px 20px !important;
  }
  .navigation-card-container {
    // max-width: calc(50% - 50px);
    flex-basis: inherit;
    flex: 1 1 200px;
    width: 100%;
  }
  .nav-item {
    cursor: pointer;
    width: 100%;
    flex-basis: calc(
      50% - 5px
    ); // Adjust the subtraction value to account for the gap
    flex-grow: 1;
    text-align: left; // Optional: for centering text
    padding: 10px; // Optional: adjust as needed
    box-sizing: border-box; // Ensures padding and border are included in the width calculation
  }
  .navigation-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; // Optional: adjust as needed for spacing between items
    .nav-item {
      cursor: pointer;

      flex-basis: calc(
        50% - 5px
      ); // Adjust the subtraction value to account for the gap
      flex-grow: 1;
      text-align: left; // Optional: for centering text
      padding: 10px; // Optional: adjust as needed
      box-sizing: border-box; // Ensures padding and border are included in the width calculation
    }
  }

  .notification-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    padding: 0px 50px;
    gap: 10px !important;
  }
  .notification-card-container .carousel {
    display: block;
  }

  .top-page-cards, .top-page-graphs {
    flex-direction: column;
  }
  .top-page-graphs {
    .card-dashboard {
      width: 100% !important;
    }
  }
}

@media (max-width: 390px) {
  .header-card {
    width: auto  ;
  }
}

@media (max-width: 767px) {
  .notification-card {
    gap: 10px !important;
    padding: 0px 20px !important;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .header-card {
    min-height: 150px;
    min-width: calc(100% + 30px) !important ;
  }
  /* Styles for iPhone 14 Plus Portrait and Landscape */
}
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .header-card {
    min-height: 150px;
    min-width: calc(100% + 20px) !important ;
  }
  /* Styles for iPhone 14 Pro Portrait and Landscape */
}
@media (max-width: 852px) and (max-height: 393px) {
  .header-card {
    flex: 1 1 200px;
    min-height: 150px;
    min-width: calc(100% + 20px) !important ;
  }
  /* Styles for iPhone 14 Pro Portrait and Landscape */
}

// @media (max-width: 430px) {
//   .header-card {
//     min-width: calc(100% + 20px);
//     right: 30px;
//     position: relative;
//   }
// }

@media (device-width: 926px) and (device-height: 428px) {
  .header-card {
    min-height: 150px;
    width: 100% !important ;
  }
  /* Styles for iPhone 14 Plus Portrait and Landscape */
}
