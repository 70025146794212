.form-actions {
  flex-direction: row;
  justify-content: end;
  margin-top: 40px;
  .amplify-button {
    width: 355px !important;
  }
  .amplify-button--outlined--primary {
    background-color: #4365de !important;
    color: #ffff !important;
  }
  .amplify-button--outlined--secondary {
    background-color: #f3f6fc !important;
    color: #1a1c1e !important;
    width: 250px !important;
    svg {
      stroke: #1a1c1e;
      margin-right: 5px;
    }
  }
}

.amplify-divider::after {
  left: 10px !important;
}

#Tab2-panel {
  display: none;
}

.amplify-tabs__list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 50%;
}

.amplify-tabs__item {
  margin-top: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 50%;
  &.amplify-tabs__item--active {
    color: #000000;
    border-bottom: 2px solid #393a94 !important;
  }
}

.amplify-tabs__panel--active {
  display: flex;
  justify-content: flex-end;
  .eq-index--carousel-item {
    background: #ffffff;
    text-align: center;
    width: 400px;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sp-view {
  display: none;
}

.pc-view {
  .eq-header-details {
    .status {
      background-color: #f0f9f3 !important;
      color: #1c8c6e !important;
      width: fit-content;
      font-weight: 500px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      padding: 10px 20px;
      border-radius: 10px;
    }
    .category {
      border: 1px solid #4365de;
      border-radius: 10;
      padding: 5px 20px;
      color: #4365de;
      width: fit-content;
      font-weight: 500px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      border-radius: 10px;
    }
    .name {
      padding: 5px 20px;
      width: fit-content;
      font-weight: 500px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
    }
  }
}

@media (max-width: 768px) {
  .eq-schedule-card-container {
    display: none;
  }
  .pc-view {
    display: none;
  }
  .sp-view {
    display: block;
    .eq-header-details {
      .status {
        background-color: #f0f9f3 !important;
        color: #1c8c6e !important;

        font-weight: 500px;
        font-size: 8px;
        line-height: 20px;
        letter-spacing: 0.17px;
        padding: 10px 5px;
        border-radius: 10px;
        align-content: center;
      }
      .category {
        border: 1px solid #4365de;
        border-radius: 10;
        padding: 5px 5px;
        color: #4365de;
        font-weight: 500px;
        font-size: 8px;
        line-height: 20px;
        letter-spacing: 0.17px;
        border-radius: 10px;
        align-content: center;
        text-align: center;
      }
      .name {
        padding: 5px 5px;
        width: fit-content;
        font-weight: 500px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }
    .add-eq-details {
      .custom-button {
        background-color: transparent !important;
        border: none !important;
        min-width: 50px !important;
      }
    }
    .form-divider {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .modified-tab {
      border: none !important;
      background-color: transparent;
      // margin-top: 20px;
    }
    .amplify-tabs__list {
      display: flex;
      flex-direction: column;
      width: inherit !important;
      border-bottom: 2px solid #ddd;
      #Tab1-tab {
        width: inherit;
        text-align: left;
        font-size: 2em; // h6 font-size
        // font-weight: bold;
        color: black;

        border-top: 2px solid #ddd !important;
        border-bottom: 2px solid #ddd !important;
      }
      #Tab2-tab {
        .amplify-flex {
          flex-direction: row !important;
        }
      }
    }
  }
}

.image-gallery {
  position: relative;
}

.image-gallery-bullets {
  position: absolute;
  bottom: -30px; /* 画像の下に余白をつけて配置 */
  width: 100%;
  text-align: center;
}

.image-gallery-slide-wrapper {
  padding-bottom: 40px; /* bulletsの分の余白を確保 */
}
