@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-weight: 300;
  src: url('../../public/fonts/Hiragino_Kaku_Gothic_ProN_W3.otf');
}

@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-weight: 300;
  src: url('../../public/fonts/Hiragino_Kaku_Gothic_Pro_W3.otf');
}

@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-weight: 600;
  src: url('../../public/fonts/Hiragino_Kaku_Gothic_ProN_W6.otf');
}

@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-weight: 600;
  src: url('../../public/fonts/Hiragino_Kaku_Gothic_Pro_W6.otf');
}