.notice-cell {
  position: relative;
  padding: 10px 16px 50px 0 !important;
}

.notice-date {
  font-size: 12px;
  color: #ACB5BB;
  position: absolute;
  left: 0;
  top: 10px;
}

.notice-note {
  font-size: 14px;
  color: #6C7278;
  display: inline-block;
  vertical-align: top;
  padding-left: 100px;
  word-break: break-all;
}

.notice-status {
  font-size: 10px;
  color: #F2C94C;
  border: 1px solid;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 40px;
}

@media only screen and (max-width: 769px) {
  .notice-date, .notice-status {
    left: 16px;
  }
}