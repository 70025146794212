.modal {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
}
.modal.open {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.modal-content {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 706px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0);
  padding: 52px 43px;
  z-index: 13;
  padding: 56px 50px;
  @media (max-width: 767px) {
    top: 86%;
    left: inherit;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    padding: 80px 20px;
  }

  .amplify-button .amplify-text {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.company-action-modal .modal-content {
  @media (max-width: 767px) {
    padding: 24px;
    border-radius: 24px;
  }
}


.modal__overlay {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}


.close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  @media (max-width: 767px) {
    top: 10px;
  }
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 18px;
  width: 2px;
  background-color: #292D32;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.blur-bg {
  position: absolute;
  top: 80px;
  left: 255px;
  width: 100%;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  @media (max-width: 767px) {
    left: 0;
    height: 155vh;
  }
}

.custom-table {
  margin-top: 30px;
  &__eq {
    height: 200px;
    overflow: auto;
  }
}

.list {
  border: 1px solid #DCE4E8;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
}


.white-container {
  background-color: #000;
  max-width: 460px;
  width: 100%;
  border-radius: 20px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 20px;
}