.help-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 552px;
  position: relative;
  cursor: pointer;

  .downloadable-file {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.amplify-card.help-card {
  max-width: 508px !important;
  border-radius: 12px;
  min-width: 508px !important;
  // justify-content: space-between;
  .help-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.qr-list {
  padding-inline-start: 20px;
  li {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 22px;
  }
}

.eq-buttons {
  button {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .help-container {
    padding: 0px 20px;
    .amplify-card.help-card {
      border-radius: 12px;
      min-width: 100% !important;
    }
    .amplify-card {
      right: 0px;
    }
    .help-wrapper {
      display: flex;
      flex-direction: row;
      align-self: center;
      gap: 20px;
      padding-left: 5px;
      justify-content: space-between;
    }
  }
  .main-footer {
    position: relative;
    // top: 250px;
  }
}
