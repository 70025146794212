/* Calendar.css */
.calendar {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  padding: 0px 10px 30px 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.amplify-flex {
  border: none !important;

  &.button-group {
    @media (max-width: 1200px) {
      flex-direction: column !important;
      & > .amplify-flex {
        flex-direction: column !important;
        & > * {
          max-width: 250px !important;
        }
      }
    }
  }
}
.calendar table {
  width: 100%;
  border: none !important;
  border-collapse: collapse;
}

.calendar th,
.calendar td {
  width: 14.2857%; /* 100% divided by 7 days */
  text-align: center;
  padding: 30px 10px;
}

.calendar th {
  padding: 10px 0px;
}

.other-month {
  // background-color: #f9f9f9;
  color: #ccc;
}

.amplify-table__td {
  border: none;
}

.day-date {
  cursor: pointer;
  .range-day {
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    background-color: #4365de;
    position: relative;
    bottom: 40% !important;
  }
  .current-day {
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    background-color: #393a94;
  }
  .off-range-day {
    background-color: transparent !important;
  }
  .today-event {
    background-color: transparent !important;
    color: gray !important;
    opacity: 20% !important;
  }
  .range-day-event {
    color: gray;
    opacity: 20% !important;
  }
  .range-event-day {
    background-color: grey;
    color: white;
    border-radius: 10px;
    position: relative;
    overflow: visible;
    opacity: 20% !important;
  }
  .range-event-day::before {
    z-index: 10;
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    width: 70%;
    height: 1px; /* Adjust thickness of the line */
    background-color: grey; /* Match text color */
    transform: translateX(-50%) rotate(12deg);
  }

  .off-range-event-day {
    background-color: transparent;
    color: grey;
    border: 2px solid grey;
    border-radius: 10px;
    position: relative;
    overflow: visible;
    opacity: 20% !important;
  }

  .off-range-event-day::before {
    z-index: 10;
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    width: 70%;
    height: 1px; /* Adjust thickness of the line */
    background-color: grey; /* Match text color */
    transform: translateX(-50%) rotate(12deg);
  }
  .day-with-events {
    position: relative;
    overflow: visible; /* Ensure pseudo-element is visible */
  }

  .day-with-events::before {
    z-index: 10;
    content: "";
    position: absolute;
    bottom: 73%;
    left: 50%;
    width: 70%;
    height: 1px; /* Adjust thickness of the line */
    background-color: #333; /* Match text color */
    transform: translateX(-50%) rotate(15deg); /* Rotate line */
  }
}

.custom-toolbar {
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: 700;
  .toolbar-label {
    // align-items: center;
    margin: 0px 40px;
  }
}

.calendar-table-wrapper {
  .pc-view {
    display: flex;
    flex-direction: column;
    .amplify-table__head {
      .amplify-table__row {
        display: flex;
        width: inherit;
      }
    }
    .amplify-table__row {
      display: flex !important;
      width: inherit !important;
    }
  }
}

@media (max-width: 768px) {
  .calendar {
    padding: 0px 00px 30px 00px !important;
  }
  .day-date {
    height: 55px !important;
    width: 45px;
    .range-day {
      padding: 10px 10px;
      border-radius: 10px;
      color: white;
      background-color: #4365de;
      bottom: 0px !important;
    }
    .off-range-day {
      padding: 10px 7.5px;
      border-radius: 10px;
      color: #ddd !important;
    }
    .range-event-day {
      background-color: grey;
      color: white;
      border-radius: 10px;
      position: relative;
      overflow: visible;
      opacity: 20% !important;
      max-height: 18px;
      font-size: xx-small;
      padding: 0px 2px;
      font-size: 6px;
      align-content: center;
    }

    .range-event-day::before {
      z-index: 10;
      content: "";
      position: absolute;
      top: -17px;
      left: 50%;
      width: 85%;
      height: 2px; /* Adjust thickness of the line */
      background-color: grey; /* Match text color */
      transform: translateX(-50%) rotate(12deg);
    }
    .range-day-event {
      position: relative;
      top: 10px;
    }
  }
  .other-month {
    // background-color: #f9f9f9;

    padding: 5px 5px !important;
  }
  .current-month {
    padding: 5px 5px !important;
  }
  .custom-toolbar {
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 700;
    .custom-button {
      background-color: transparent !important;
      border: none !important;
      min-width: 50px !important;
      border: 2px solid #ddd !important;
      border-radius: 10px !important;
    }
  }
  .calendar-table-wrapper {
    display: flex;
    flex-direction: column;
    .calendar-month-wrapper {
      display: flex;
      flex-direction: column;

      .calendar td {
        width: 10.2857%; /* 100% divided by 7 days */
        text-align: center;
        padding: 30px 0px;
      }
    }
    // .pc-view {
    //   display: none;
    // }
  }
  .pc-view {
    display: none;
  }
  .sp-view {
    display: block;
    .header-row {
      display: flex;
      min-width: 100% !important;
    }
  }
  //! Events
  // .day-date .range-event-day {
  //   background-color: grey;
  //   color: white;
  //   border-radius: 10px;
  //   position: relative;
  //   overflow: visible;
  //   opacity: 20% !important;
  //   max-height: 18px;
  //   font-size: smaller;
  // }

  .calendar table {
    border: none !important;
    border-collapse: collapse;
  }

  thead {
    width: 100%; /* Ensure thead takes full width */
  }

  thead tr {
    width: inherit; /* Inherit width from thead */
  }

  .eq-schedule-cards {
    .amplify-card {
      right: auto;
    }
  }
}

@media (max-width: 1049px) {
  .amplify-flex {
    &.eq-schedule-cards  {
      overflow: auto;
      padding: 20px;
    }
  }
}