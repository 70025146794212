.title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  .other-content {
    margin-bottom: 10px;
  }
  .last-content {
    margin-bottom: 40px;
  }
}
.section-title {
  margin-bottom: 10px;
}
.sub-content {
  padding-left: 60px;
  margin-bottom: 10px;
}
.last-sub-content {
  padding-left: 60px;
  margin-bottom: 40px;
}
.section {
  padding-left: 40px;
  margin-bottom: 10px;
}
.last-section {
  padding-left: 40px;
  margin-bottom: 40px;
}
