.board-container {
  width: 1160px;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 60px;
  .modal-content {
    & > .amplify-text {
      color: #000000 !important;
    }
  }
  .custom-form {
    .amplify-divider {
      border-bottom-width: 1px;
    }
    .amplify-label {
      color: rgba(0, 0, 0, 0.60);
    }
    .form-note {
      font-weight: 600;
      font-size: 16px;
      margin-top: -10px;
      margin-bottom: 25px;
    }
  }
}

.board--header {
  .amplify-heading--1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 37.3px;
    letter-spacing: -1.5px;
    color: #444444;
    padding: 5px 0;
  }
}

.board--progress {
  .amplify-card {
    position: relative;
    flex: 1;
    align-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    @media (max-width: 767px) {
      padding: 16px 5px;
      text-align: center;
    }
  }
  .amplify-heading--6 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: .17px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .card--current {
    background: #999999;
    .amplify-heading--6 {
      color: #ffffff;
    }
  }

  svg {
    @media (max-width: 767px) {
      width: 18px;
      padding: 0px 2px;
    }
  }
}

.board--contents {
  margin-bottom: 30px;
  .amplify-heading--3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.5px;
    margin: 20px 0;
  }
  .amplify-heading--4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin: 20px 0;
  }
  .amplify-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .amplify-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .scrollable-card {
    max-height: 10em; /* 3行分の高さに制限 */
    overflow-x: hidden; /* 横スクロールを有効化 */
    overflow-y: auto; /* 縦スクロールを無効化 */
    white-space: normal; /* テキストの折り返しを有効化 */
    padding: 1em; /* 適宜調整してください */
    border: 1px solid #ccc; /* 必要に応じて調整してください */
    background-color: #f9f9f9; /* 必要に応じて調整してください */
    word-break: break-word;
  }
  .amplify-text {
    margin-bottom: 10px;
  }
}

.board--footer {
  margin-top: 20px;
}