a {
  text-decoration: none;
  color: initial;
}

.card {
  width: 100%;
  height: 97px;
  position: relative;

  @media (min-width: 767px) {
    width: 341px;
  }
}

.card-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
}

.card-arrow {
  background-color: #2196F3;
  border-radius: 100%;
  width: 24px;
  height: 24px;
}

.card-small-text{
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.17px;
}

.card-link {
  text-decoration: none;
}


.inactive-row {
  background-color: #f5f5f5;
  pointer-events: none;
}

.table-hover {
  &:hover {
    text-decoration: underline;
    opacity: 0.5;
  }
}

.auth-table,
.record-control,
.record-control .amplify-table,
.auth-table .amplify-table {
  border-spacing: 7px;
  border-collapse: separate;
}

.table-scroll {
  overflow-y: auto;
}

.auth-table,
.record-control {
  @media (max-width: 555px) {
    width: 555px !important;
    display: grid;
  }
  tr {
    height: 54px;
    table-layout: fixed;
    display: table;
    width: 100%;
  }
  th, td {
    height: 54px;
    // border: 1px solid #fff;
  }
  th {
    font-weight: 500;
    font-size: 16px;
    background-color: #616161;
    color: #fff;
  }
  td {
    text-align: left;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    // width: 33.33%;
    padding: 0;
    vertical-align: top;

    .cell-span {
      text-align: left;
      display: flex;
      // justify-content: center;
      width: 100%;
      height: 100%;
      min-height: 35px;
      padding: 15px 16px 0;
      color: #0d1926;
      font-size: 1rem;
      font-weight: 700;
      &.has-border-t {
        border-top: 3px solid #fff;
      }
      &.gray-border {
        justify-content: center;
        min-height: 54px;
        border-bottom: 1px solid #DCE4E8;
      }
    }

    .amplify-flex.gray-border-checkbox {
      padding: 0 30px;
      min-height: 54px;
      width: 100%;
      justify-content: end;
      border-bottom: 1px solid #DCE4E8 !important;
      // justify-content: right !important;
    }

    span:first-of-type {
      border-top: 0;
    }
  }
  .no-border-t td {
    border-top: 0;
  }
  .has-border-b {
    border-bottom: 3px solid #fff;
  }
}

.record-control {
  td {
    width: 12.5%;
  }
}

.dateIcon {
  z-index: -9;
}

.category-name {
  position: relative;
  // @media (max-width: 767px) {
  //   max-width: calc(100% - 110px);
  // }
}

.category-name-list {
  position: relative;
  cursor: pointer;
    border-left: 3px solid #4365de;
    padding: 0px 20px;
    max-height: inherit;
    @media (min-width: 767px) {
      min-width: 19rem;
    }
}

.card-btn {
  opacity: 1 !important;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.card-dropdown-holder {
  position: relative;
  height: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.card-dropdown {
  list-style: none;
  padding: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.22);
  top: calc(100% - 10px);
  margin: 0;
  z-index: 15;
  left: 1rem;
  padding: 1rem;
  width: calc(100% - 2rem);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid rgba(0,0,0,0.22);

  li {
    cursor: pointer;
  }

  li:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

.card-dashboard {
  width: 100%;
  min-width: auto;
}

.card-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.record-control-buttons {
  border-bottom: 1px solid #DCE4E8 !important;
}

.auth-table-head {
  th {
    min-height: 35px;
    padding: 0;
    background: #1F2937;
    font-size: 1rem;
    font-weight: 700;
    padding: 12px 16px;
  }
}

.card-dropdown-item:hover{
  background-color: #e5f2fd; 
}