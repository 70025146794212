.main-footer {
  text-align: center;
  padding: 30px 70px;
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  text-align: left;
  @media (max-width: 767px) {
    padding: 30px 20px;

    &.safari-only {
      padding-bottom: 62px;
    }
  }

  &.not-authenticated {
    top: 3rem;
    padding: 20px 0 20px;
    .amplify-text {
      text-align: center;
    }
    .footer-menu,
    .arrow-up {
      display: none !important;
    }
  }
}

@media (max-width: 1024px) {
  .main-footer {
    &.not-authenticated {
      top: 10rem;
      padding: 20px 0 20px;
    }
  }
}

.arrow-up {
  cursor: pointer;
  position: fixed;
  z-index: 1;
  padding: 15px;
  display: inline-block;
  background: #3e4c59;
  height: 56px;
  width: 56px;
  bottom: 0;
  right: 0;
}

.footer-menu {
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;
  left: 0;
  list-style: none;
  background: #ffffff;
  border-top: 1px solid #dce4e8;
}

.footer-scan-icon {
  .footer-link-item {
    padding: 0;
  }
  .footer-link-icon {
    padding: 18px;
    max-height: 64px;
    max-width: 64px;
    background: #4365de;
    border-radius: 50%;
    margin-top: -25px;
    box-shadow: 0px 6px 24px 0px #1262fb52;
  }
}

.footer-link-item {
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: -0.02em;
  position: relative;
  padding: 40px 0;
  &.footer-link-active {
    &::before {
      content: "";
      border-top: 3px solid #4365de;
      height: 1px;
      width: 40%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .footer-link-text {
      color: #4365de;
    }
  }
}

.footer-link-icon {
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  &.footer-menu-icon {
    top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .not-authenticated {
    top: -1rem !important;
  }
}
