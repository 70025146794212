.has-notif {
  // position: relative;
  // width: 24px;
  // height: 24px;
}
.has-notif::after {
  // position: absolute;
  // content: '';
  // top: -7px;
  // right: -5px;
  // width: 8px;
  // height: 8px;
  // border-radius: 50%;
  // background: #0288D1;
}

.custom-button {
  min-width: 145px;
  max-width: 100%;
  &.no-min {
    min-width: 0;
  }
}

.auth-list-actions {
  .custom-button {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}