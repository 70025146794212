.main-sidebar {
  // background: #eeeeee;
  // width: 200px;
  background: #F9F6F678; // v2
  width: 256px; // v2
  border-right: 1px solid #DCE4E8;
  position: relative;
  left: 0;
  top: 0;
  padding-top: 120px;
  height: 100%;
  transition: ease-in-out .2s;
  z-index: 2;
  // overflow: hidden;
  white-space: nowrap;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background: #F6F7F9;

  &::before {
    content: '';
    position: absolute;
    right: 100%;
    height: 100vh;
    width: 100vw;
    z-index: 4;
    background: rgba(0, 0, 0, .4);
  }

  &.collapse {
    width: 87px;
    .menu-arrow-icon {
      // display: none;
      opacity: 0;
      pointer-events: none;
    }
    .sidebar-icon {
      left: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar {
    position: fixed;
    left: -100%;
    top: 0;
    width: 250px;
    transition: .5s ease-in-out;
    height: calc(100vh - 92px);
    padding-top: 50px;
  
    &.active {
      left: 0 !important;

      &.safari-only {
        padding-bottom: 150px;
      }
    }
  }
}

.sidebar-ul,
.sidebar-subul,
.sidebar-other-menu {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.sidebar-other-menu {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #DCE4E8;
}

.sidebar-li {
  position: relative;
}

.sidebar-link,
.sidebar-other-link {
  padding: 13px 10px 13px 87px;
  // border-bottom: 1px solid #BDBDBD;
  // letter-spacing: 0.15px;
  // line-height: 24px;
  display: block;
  font-size: 16px;
  // font-weight: 600;
  // color: #000000;
  text-decoration: none;
  transition: ease-in-out .2s;

  // v2
  font-weight: 300;
  line-height: 22.4px;
  letter-spacing: 0.06em;
  color: #5D6A77;
  position: relative;
}

.sidebar-link,
.sidebar-other-link {
  &.link-active {
    background: var(--primary-selected, rgba(33, 150, 243, 0.08));
  }
}

.sidebar-other-link:hover,
.sidebar-link:hover {
  border-radius: 4px;
  background: var(--primary-selected, rgba(33, 150, 243, 0.08));
}

.sidebar-subli .sidebar-link,
.sidebar-other-link {
  display: block;
  border: none;
  line-height: 20px;
  letter-spacing: 0.17px;
  padding: 8px 10px 8px 70px;
  font-size: 14px;
  font-weight: 300;
}

.sidebar-other-link{
  padding-left: 45px;
}

.sidebar-subli .sidebar-link {
  padding-left: 87px;
}

// .sidebar-li.has-submenu {
//   border-bottom: 1px solid #BDBDBD;
// }

.has-submenu {
  position: relative;
}

.has-submenu .sidebar-link {
  border-bottom: none;
}

.sidebar-subul {
  overflow: hidden;
  max-height: 0;
  transition: ease-in-out .3s;
}

.has-submenu.active .sidebar-subul {
  max-height: initial;
}

.menu-arrow-icon {
  cursor: pointer;
  position: absolute;
  // right: 0;
  // top: 9px;
  transition: ease-in-out .2s;

  // v2
  right: 25px;
  top: 13px;
}

.has-submenu.active .menu-arrow-icon {
  transform: rotate(180deg);
  top: 8px;
}

.sidebar-icon {
  pointer-events: none;
  position: absolute;
  left: 40px;
  top: 12px;
  transition: .5s ease;
}

.sidebar-collapse {
  position: absolute;
  left: 235px;
  top: 90px;
  cursor: pointer;
  background: #5D6A77;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 41px;
  overflow: hidden;
  z-index: 100;
  transition: .2s ease !important;
  &.active {
    transform: rotate((180deg));
    top: 90px;
    left: 87px;
  }
}

.menu-dot-icon {
  cursor: pointer;
  text-align: center;
}

