@import url("../src/styles/fonts.scss");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

:root {
  --default-font-size: 14px;
  --primary-font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN",
    sans-serif;
  --default-color: #000000; /* black */
  --default-font-weight: 300;
  --font-weight-bold: 600;
}

body {
  margin: 0;
  font-family: var(--primary-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--default-font-weight);
  font-size: var(--default-font-size);
  color: var(--default-color);
  // background: #F5F5F5;
  background: #ffffff; // v2
  overflow: hidden;

  &.hidden {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:hover {
  transition: 0.4s ease-in-out;
}

.error-fetch-message {
  color: red;
  text-align: center;
  font-weight: 600;
}

.main-content {
  gap: 0;
}

.main-body {
  flex: 1;
  height: calc(100vh - 80px);
  overflow: auto;
  position: relative;
  transition: 0.5s ease-in-out;
  & > * {
    transition: 0.2s ease-in-out;
  }

  // @media only screen and (max-width: 767px) {
  //   height: 100vh;
  // }

  &.hidden {
    overflow: hidden;
  }
}
.is-login {
  height: 100vh !important;
}

@media only screen and (max-width: 767px) {
  .main-body {
    &.has-menu {
      overflow: hidden;
      & > :not(.main-footer) {
        filter: blur(5px);
        pointer-events: none;
      }
    }

    &.hidden {
      overflow: hidden;
    }
  }
}

.page-content-body {
  margin-top: -10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ffffff;
}

.page-content-header {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
}

.main-banner {
  background: url("../public/images/banner-bg.png") no-repeat center;
  background-size: cover;
  min-height: 158px;
  // padding: 20px 40px;
}

.page-header {
  font-size: 32px;
  font-weight: 300;
  line-height: 38.4px;
  text-align: center;
  color: #ffffff;
  padding: 20px 0;
}

.banner-select {
  .amplify-select {
    background-color: transparent !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: -0.02em;
    border: none !important;
  }
}

.sp-header-select {
  display: none;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    // position: absolute;
    // right: 0;
    // top: 0;
    // bottom: 0;
    margin: auto;
    justify-content: center;

    .amplify-select {
      background-color: transparent !important;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      letter-spacing: -0.02em;
      border: none !important;
      text-align: center;
    }
  }
}

@media print {
  body {
    padding: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: visible !important;

    &.hidden {
      overflow: hidden;
    }
  }

  @page {
    size: A4;
  }

  .main-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: visible !important;

    &.hidden {
      overflow: hidden;
    }
  }

  .main-content {
    overflow: visible !important;
  }

  .main-banner {
    padding: 0 !important;
    height: 52px !important;
  }

  .hide-from-print {
    display: none !important;
  }

  .page-header {
    padding: 0 !important;
  }

  .unflex-on-print {
    flex-wrap: wrap !important;
    div {
      width: 100% !important;
    }
  }

  .arrow-up {
    display: none !important;
  }
}

.table-row {
  cursor: pointer;
}

.amplify-table__td {
  word-break: break-all;
}


// placeholder
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #CDD2D6;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #CDD2D6;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #CDD2D6;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #CDD2D6;
}

.no-value .amplify-select {
  color: #CDD2D6;
}