.section-title {
  text-align: center;
  margin-bottom: 20px;
}
.content {
  margin-bottom: 40px;
}
.sub-content-title {
  font-weight: 700;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 10px;
}

.other-content {
  margin-bottom: 10px;
}

.last-content {
  margin-bottom: 40px;
}

.heading-title {
  text-decoration: underline;
  font-weight: 700;
  margin-bottom: 10px;
}
.sub-content {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 10px;
}

.sub-content-title{
    font-weight: 700;
    padding-left: 20px;
    margin-bottom: 10px;
}

.last-sub-content {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 40px;
}

.sub-other-conent{
    text-align: left;
    padding-left: 40px;
    margin-bottom: 10px;
}
//* Section 4 Class

.sub-content-list {
    padding-left: 20px;
    .list-title {
        font-weight: 700;
        margin-bottom: 10px;
    }
    .content {
        margin-bottom: 40px;
      }
      .other-content {
        margin-bottom: 10px;
      }
      .last-content{
        margin-bottom: 40px;
      }
      .other-sub-content{
        padding-left: 20px;

      }
}