.forgot-pass--header {
  margin-bottom: 30px;
  .amplify-breadcrumbs__link {
    font-size: 16px;
    color: #000000 !important;
  }
  .amplify-breadcrumbs__link--current {
    font-weight: 600;
  }
  .amplify-heading--1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 5px;
  }
}

.forgot-pass--form-header {
  margin-top: 60px;
  .amplify-heading--2 {
    font-size: 24px;
    font-weight: 700;
  }
  .amplify-text {
    font-size: 16px;
    font-weight: 600;
  }
}

[data-amplify-authenticator] {
  [data-amplify-router] {
    border: none;
    background: transparent;
    box-shadow: 0 0 0 0;
  }
}

[data-amplify-authenticator-forgotpassword] {
  fieldset.amplify-flex {
    // & > .amplify-flex:nth-child(3) {
    // display: none;
    // }
    .modal {
      .modal-content {
        width: 649px;
      }
      .amplify-text:last-of-type {
        color: #000000 !important;
      }
      .amplify-button {
        .amplify-text {
          color: inherit !important;
        }
      }
    }
  }
}

[data-amplify-authenticator-confirmresetpassword],
[data-amplify-authenticator-forcenewpassword] {
  background-color: var(
    --amplify-components-authenticator-router-background-color
  );
  box-shadow: var(--amplify-components-authenticator-router-box-shadow);
  border-color: var(--amplify-components-authenticator-router-border-color);
  border-width: var(--amplify-components-authenticator-router-border-width);
  border-style: var(--amplify-components-authenticator-router-border-style);
}

.forgot-password {
  width: 1160px;
  max-width: 90%;
  margin: 0 auto;
  & > .main-content {
    padding-bottom: 50px;
  }
  .modal {
    left: 50%;
    .modal-content {
      width: 649px;
    }
    .amplify-text:last-of-type {
      color: #000000 !important;
    }
    .amplify-button {
      .amplify-text {
        color: inherit !important;
      }
    }
  }
}

//* Version 2 UI UPDATE
.forgot-password-container {
  flex-direction: row;
  width: 100vw;
  margin: 0 auto;
  .forgot-password-main {
    width: 50%;
    background: url("../../public/images/login-image.jpg");
    background-size: 1450px;
    height: 100vh;
    background-repeat: no-repeat;
    background-color: #1f2937;
  }
  .forgot-password-headline {
    position: relative;
    top: 50%;
    left: 10%;
  }
  .forgot-password-icon {
    position: relative;
    top: 0%;
    left: 90%;
  }

  .forgot-password-form {
    width: 552px;
    max-width: 100%;
    min-height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 18rem 2rem 0 2rem;

    .amplify-heading {
      text-align: center;
      margin-bottom: 20px;
    }
    .amplify-input {
      margin-bottom: 3rem;
      border-radius: 25px;
    }
    .amplify-button {
      width: 100%;
      height: 43px;
      margin-bottom: 12rem;
      border-radius: 25px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .forgot-password-container {
    display: flex;
    flex-direction: column !important;
    margin: 0 auto;
    .forgot-password-headline {
      position: relative;
      top: 55%;
      left: 5%;
    }

    .forgot-password-icon {
      position: relative;
      top: 0%;
      left: 90%;
    }
    .forgot-password-main {
      background: url("../../public/images/login-image.jpg");
      background-repeat: no-repeat;
      background-size: 750px;
      height: 436px;
      width: 100%;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
      clip-path: ellipse(511px 235px at 38.71% 33.08%);
    }
    .forgot-password-form {
      width: 552px;
      min-height: 100%;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 0 2rem;
    }
    .modal-content {
      height: 80rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .forgot-password-container {
    .forgot-password-headline {
      top: 20%;
      width: 85%;
      // .amplify-heading--1 {
      //   font-size: 30px;
      // }
      // .amplify-heading--5 {
      //   font-size: 17px;
      // }
    }
  }
}