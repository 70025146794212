.eq-rr-container {
  width: 80% !important;
  .amplify-button {
    border-radius: 10px !important;
  }
  .sp-view {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .eq-rr-container {
    width: 100% !important;
    .pc-view {
      display: none;
    }
    .sp-view {
      display: block;
      gap: 10;
    }
  }
}
