.schedule-details {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  padding: 50px 0px;
  .pc-view {
    .form-actions {
      flex-direction: row;
      justify-content: end;
      margin-top: 40px;
      .amplify-button {
        width: 355px !important;
      }
    }
    .eq-header-details {
      .status {
        background-color: #f0f9f3 !important;
        color: #1c8c6e !important;
        width: fit-content;
        font-weight: 500px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        padding: 10px 20px;
        border-radius: 10px;
      }
      .category {
        border: 1px solid #4365de;
        border-radius: 10;
        padding: 5px 20px;
        color: #4365de;
        width: fit-content;
        font-weight: 500px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        border-radius: 10px;
      }
      .name {
        padding: 5px 20px;
        width: fit-content;
        font-weight: 500px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }
  }
}
@media (max-width: 768px) {
  .sp-view {
    .form-actions {
      display: flex;
    }
  }
   
    .amplify-flex.pc-view.form-actions {
      display: none !important;
    
  }
}
