@import "react-big-calendar/lib/sass/styles";

.react-calendar__month-view__weekdays {
  gap: 5px;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  background-color: #e0e0e0;
  padding: 3px 0px 3px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  flex: 1 !important;
  flex-basis: calc(100% / 7.4) !important;
}

.react-calendar__month-view__days {
  gap: 5px;
}

.react-calendar__month-view__days__day {
  flex: 1 !important;
  flex-basis: calc(100% / 7.4) !important;
  border-width: 0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff !important;
  position: relative;
  padding: 10px 0 50px;
  cursor: pointer;
  &.returned-status {
    pointer-events: none;
    abbr {
      &:not(.has-event)::before {
        background: #0000008f;
        color: #ffffff;
      }
    }
  }
  abbr {
    font-size: 12px;
    font-weight: 700;
    line-height: 17.38px;
    &.has-event {
      &:after {
        content: attr(data-company);
        position: absolute;
        width: 100%;
        background: #707070;
        z-index: 1;
        bottom: 10px;
        color: #ffffff;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 20px;
        font-weight: bold;
        min-height: 30px;
        padding: 6px 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.first-event::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.last-event::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.mid-event::after {
      border-radius: 0;
    }

    &.has-event::after {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
      padding-left: 5px;
    }
    
    &.has-auth::after {
      background: #2196f3 !important;
    }

    &.color-return::after {
      background: #4968d6 !important;
    }

    &.color-in_use::after {
      background: #f7e3d8 !important;
      color: #b02318 !important;
    }

    &.color-in_schedule::after {
      background: #4795ec !important;
    }

    &:not(.has-event) {
      &:before {
        content: "登録";
        position: absolute;
        width: 100%;
        bottom: 10px;
        font-size: 13px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.46000000834465027px;
        padding: 4px 10px 4px 10px;
        background: #dae7fc;
        border-radius: 3px;
        left: 50%;
        transform: translateX(-50%);
        color: #315e95;
        overflow: hidden;
        max-height: 30px;
        text-overflow: ellipsis;
      }
    }
  }
}

.react-calendar__tile--now {
  background-color: #f3f3f3 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}

.react-calendar__navigation {
  text-align: center;
  padding: 20px;
}

.react-calendar__navigation__label {
  pointer-events: none;
  background: transparent;
  border: none;
}

.react-calendar__navigation__arrow {
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.schedule-dates-modal {
  .modal {
    .amplify-text {
      display: none;
    }
  }
}

.schedule-dates-modal--contents {
  flex-direction: column;
  width: 100%;
  .custom-table {
    margin: 0;
  }
}

.schedule-dates-modal--title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  pointer-events: none;
  abbr:before {
    display: none;
  }
}

.amplify-tabs__list {
  justify-content: flex-end;
}

.calendar-container {
  display: flex;
  flex-direction: column;

  .border-bottom {
    border-bottom: 1px solid #dce4e8;
  }
  .calendar-week-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    .form-divider {
      margin: 0px 0px !important;
    }
    .calendar-week-main {
      border-top: 1px solid #dce4e8;
      padding: 20px 0px;
      .action {
        margin-right: 10px;
      }
      .checkbox{
        margin-left: 2px;
      }
      .company-details {
        margin-right: 10px;
        margin-left: 24px;
      }
      .scheduled {
        background-color: #dce4e8;
        padding: 10px 20px;
        border-radius: 5px;
      }
      .week-detail-wrapper {
        display: flex;
        flex-direction: row;
        .calendar-week-details {
          flex: 1;
          width: 100%;
          flex-direction: column;
          text-align: center;
          .week-day {
            padding: 10px 20px;
          }
          .company-name {
            margin-top: -10px;
            font-size: small;
            font-weight: bold;
          }
        }
      }
    }
  }

  .calendar-actions {
    border-radius: 25px !important;
    width: 200px;
  }
  .calendar-actions.disabled {
    border-radius: 25px !important;
    width: 200px;
    background-color: #e0e0e0 !important;
    color: #313030 !important; 
    cursor: not-allowed;
  }
  .sp-view {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .pc-view {
      display: none;
    }
    .form-divider {
      margin: 0px 0px !important;
    }

    .calendar-week-wrapper {
      display: flex;
      flex-direction: column;
      overflow-x: scroll;
      .calendar-week-main {
        width: inherit !important;
        .scheduled {
          padding: 10px;
        }
        .week-detail-wrapper {
          display: flex;
          flex-direction: row;
          gap: 1px;
          .calendar-week-details {
            flex: 1;
            width: 100%;
            flex-direction: column;
            text-align: center;
            .week-day {
              padding: 10px;
            }
            .company-name {
              margin-top: -15px !important;
              font-size: x-small !important;
              font-weight: bold !important;
            }
          }
        }
      }
    }
    .sp-view {
      display: block;
      gap: 10;
    }
  }
}

/* Remove borders from all headers (days of the week) */
.rbc-header {
  border: none;
}

/* Remove borders from all day cells */
.rbc-day-bg {
  border: none;
}

/* Remove borders from the month view cells */
.rbc-month-view .rbc-day-bg {
  border: none;
}
.rbc-month-row + .rbc-month-row {
  border: none;
}

.rbc-row {
  position: relative;
  bottom: 10px;
}

.rbc-calendar {
  .custom-toolbar {
    justify-content: center;
    align-items: center;

    .amplify-button > svg {
      path {
        fill: #000;
      }
    }
  }
  .rbc-month-view {
    border: none;
  }
}

.rbc-header + .rbc-header {
  border: none;
}
.rbc-date-cell + .rbc-date-cell {
  border: none;
}
.rbc-date-cell {
  text-align: center;
  position: relative;
  // top: 20px;
  padding: 10px 10px 2px 10px;
  .rbc-button-link {
    border-radius: 12px;
    width: 100%;
    // background-color: #4365de !important;
    padding: 10px 0px;
  }
}
.rbc-date-cell.rbc-now {
  .rbc-button-link {
    background-color: #393a94;
  }
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-off-range-bg {
  background-color: transparent !important; /* Use !important to ensure override */
}
.rbc-off-range {
  .rbc-button-link {
    background-color: transparent !important;
  }
}

.day-with-events {
  position: relative;
  overflow: visible; /* Ensure pseudo-element is visible */
}

.day-with-events::before {
  z-index: 10;
  content: "";
  position: absolute;
  bottom: 73%;
  left: 50%;
  width: 50%;
  height: 1px; /* Adjust thickness of the line */
  background-color: #333; /* Match text color */
  transform: translateX(-50%) rotate(15deg); /* Rotate line */
}

.rbc-row-segment {
  border: none;
  border-radius: 12px;
  padding: 0px !important;
}
.rbc-event {
  border: none;
  border-radius: 12px;
  padding: 0px !important;
  background-color: transparent !important;
}
.rbc-event-content {
  border-radius: 12px;
  text-align: center;
  background-color: #bdbdbd;
  // margin: 10px 10px 2px 10px;
  margin: 0px 10px;
}

.scroll-view::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

.scroll-view::-webkit-scrollbar-thumb {
  overflow-x: hidden;
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.scroll-view::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 0px;
}

.amplify-checkbox__label {
  font-weight: bold;
}


 

@media (max-width: 760px)  {
  .react-calendar__month-view__weekdays__weekday {
    flex-basis: calc(100% / 8.4) !important;
  }
  .react-calendar__month-view__days__day {
    flex: 1 !important;
    flex-basis: calc(100% / 8.4) !important;
    border-width: 0;
    border-bottom: 1px solid #e0e0e0;
    background-color: #ffffff !important;
    position: relative;
    padding: 10px 0 50px;
    cursor: pointer;
    abbr {
      font-size: 12px;
      font-weight: 700;
      line-height: 17.38px;
      // &.has-event {
      //   &:after {
      //     content: attr(data-company);
      //     position: absolute;
      //     width: 100%;
      //     background: #0000008f;
      //     z-index: 1;
      //     bottom: 10px;
      //     color: #ffffff;
      //     left: 50%;
      //     transform: translateX(-50%);
      //     border-radius: 20px;
      //     font-weight: bold;
      //     line-height: 10px;
      //     font-size: smaller;
      //     min-height: 30px;
      //     padding: 6px;
      //   }
      // }
      &:not(.has-event) {
        &:before {
          content: "登録";
          width: 100%;
          padding: 4px;
          // position: absolute;
          // width: 100%;
          // bottom: 10px;
          // font-size: 13px;
          // font-weight: 700;
          // line-height: 10px;
          // letter-spacing: 0.46000000834465027px;
          // padding: 4px 10px 4px 10px;
          // background: #2196f3;
          // border-radius: 3px;
          // left: 50%;
          // transform: translateX(-50%);
          // color: #ffffff;
        }
      }
    }
  }
}

