.qr-reader {
  width: 430px;
  max-width: 100%;
  height: calc(100vh - 180px);
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  .modal-content {
    .amplify-button + .amplify-text {
      margin-block-end: 30px !important;
    }
  }
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  width: 256px;
  height: 256px;
  transform: translateX(-50%) translateY(-50%);
}

.qr-result {
  color: rgb(30, 32, 35);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 20px;
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  max-width: 90%;
  word-wrap: break-word;
}

.qr-code {
  background: #DCE4E8;
  max-width: 320px;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  padding: 4px 10px;
  word-wrap: break-word;
}

// .scanned-codes {
//   margin: 0 10px;
//   &:first-of-type {
//     margin-left: 0;
//   }
//   &:last-of-type {
//     margin-right: 0;
//   }
// }

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}