[data-amplify-authenticator-signin] {
  width: 552px !important;
  max-width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 0 !important;
  border: none;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 0;
  background: #ffffff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.login-header {
  margin-top: 10vh !important;
  width: 552px !important;
  max-width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

[data-amplify-theme="custom-login"] {
  background: transparent;
  // width: 552px;
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // max-width: 90%;
  .amplify-heading {
    margin-bottom: 15px;
  }
  .amplify-field {
    position: relative;
  }
  .amplify-label {
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: -13px;
    left: 10px;
    padding: 0 5px;
    background: #ffffff;
    z-index: 1;
    border-radius: 10px;
  }
  .amplify-input {
    padding: 15px 14px;
    border-color: rgba(0, 0, 0, 0.23);
  }
  .amplify-passwordfield {
    .amplify-button {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }

  [data-amplify-authenticator] {
    [data-amplify-router] {
      border: none;
      background: transparent;
      box-shadow: 0 0 0 0;
    }
    [data-amplify-authenticator-signin] {
      .amplify-button--primary {
        background: #2196f3;
      }
    }
  }
  .modal {
    .modal-content {
      width: 600px;
    }
    .amplify-text:last-of-type {
      color: #000000 !important;
    }
  }
}

.login-footer {
  // background: #ffffff;
  // z-index: 1;
  // padding: 15px 32px 56px;
  // text-align: left;
  // width: 552px !important;
  // max-width: 90%;
  // position: relative;
  // left: 50%;
  // transform: translateX(-50%);
  // border: none;
  // border-radius: 20px;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
  // box-shadow: 0px 7px 7px 1px rgba(0, 0, 0, 0.2);
  .amplify-button {
    padding: 0;
    display: inline;
    width: auto;
    &:hover {
      background: none;
    }
    &:focus {
      border: none;
    }
    span {
      padding: 0 !important;
      font-size: 14px;
      color: #2196f3 !important;
      text-decoration: underline;
      font-weight: 500;
    }
  }
  & + .modal {
    .modal-content {
      width: 600px;
    }
    .amplify-text:last-of-type {
      color: #000000 !important;
    }
  }
}

[data-amplify-authenticator-forgotpassword] {
  width: 1160px;
  max-width: 90%;
  .amplify-label {
    display: none;
  }
}
.border-rounded {
  border-radius: 25px;
}
.border-rounded-password {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
@media only screen and (min-width: 30rem) {
  [data-amplify-authenticator] [data-amplify-container] {
    width: auto;
  }
}

.login-view {
  width: 552px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
}

.custom-login-container {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 30px;
  .amplify-input {
    margin-bottom: 20px;
  }
  .amplify-button {
    width: 100%;
  }
}

.signin-error-message {
  color: #5f2120;
  background-color: #fdeded;
  padding: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.signin-error-success {
  background-color: #ff9800;
  color: #000000;
  padding: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.error-icon {
  // flex: 1;
  flex-basis: 20px;
}

//* Version 2 UI UPDATE
.login-light-container {
  flex-direction: row;
  width: 100vw;
  margin: 0 auto;
  .login-headline {
    position: relative;
    top: 50%;
    left: 10%;
  }
  .login-icon {
    position: relative;
    top: 0%;
    left: 90%;
  }
  .login-light-main {
    width: 50%;
    background: url("../../public/images/login-image.jpg");
    background-size: 1450px;
    height: 100vh;
    background-repeat: no-repeat;
  }
  .login-form {
    width: 552px;
    min-height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 18rem 2rem 0 2rem;

    .amplify-input {
      margin-bottom: 20px;
    }

    .amplify-field-group__outer-end {
      .amplify-field-group__control:not(:focus) {
        border-radius: 0px 25px 25px 0px !important;
      }
    }
    .amplify-button {
      width: 100%;
      height: 42px;
      margin-bottom: 20px;
      border-radius: 25px !important;
    }
  }
  .login-actions {
    flex-direction: row;
    gap: 1;
    flex: 2;
    margin-top: 40px;
    color: #4365de;
  }
}

@media only screen and (max-width: 767px) {
  .main-body {
    overflow-x: hidden !important;
  }
  .border-rounded {
    border-radius: 25px;
  }
  .border-rounded-password {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }
  .login-light-container {
    flex-direction: column;
    margin: 0 auto;
    .login-headline {
      position: relative;
      top: 45%;
      left: 5%;
    }
    .login-icon {
      position: relative;
      top: 0%;
      left: 90%;
    }
    .login-light-main {
      background: url("../../public/images/login-image.jpg");
      background-repeat: no-repeat;
      background-size: 750px;
      height: 436px;
      width: 100%;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
      clip-path: ellipse(511px 235px at 38.71% 18.08%);
    }
    .login-form {
      width: 80%;
      min-height: 100%;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 0px 0px 30px 0px;
      position: relative;
      top: -50px;
    }
    .login-actions {
      flex-direction: column;
      gap: 1;
      flex: 2;
      margin-top: 40px;
      color: #4365de;
    }
  }
}

@media (max-width: 390px) and (max-height: 844px) {
  .login-light-container {
    flex-direction: column;
    margin: 0 auto;
    .login-light-main {
      background: url("../../public/images/login-image.jpg");
      background-repeat: no-repeat;
      background-size: 585px;
      height: 310px !important;
      width: 100%;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
      clip-path: ellipse(511px 235px at 38.71% 6.93%);
    }
    .login-headline {
      position: relative;
      top: 45%;
      left: 5%;
    }
    .login-icon {
      position: relative;
      top: -20%;
      left: 90%;
    }
    .login-form {
      position: relative;
    }
  }
}
