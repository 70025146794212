.eq-index--header {
  .amplify-table__td {
    border-top: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .amplify-flex {
    .amplify-heading {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      letter-spacing: -0.5px;
      text-align: left;
      word-break: break-all;
    }
    .eq-index--sub-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20.02px;
      letter-spacing: 0.17px;
      border: 1px solid;
      padding: 5px 20px;
    }
  }
  .amplify-badge {
    background-color: #2196f3;
    color: #ffffff;
  }
}

.eq-table-row {
  cursor: pointer;
}

#Tab2-panel {
  display: none;
}

.amplify-tabs__list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.eq-amplify-tabs__list {
  width: 100% !important;
  @media (max-width: 768px) {
    width: 85% !important;
    margin: 0 auto;
  }

  .amplify-tabs__item {
    @media (min-width: 768px) {
      margin-top: 0 !important;
    }
  }
}

.amplify-tabs__item {
  padding: 8.5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &.amplify-tabs__item--active {
    color: #2196f3;
    border-bottom: 2px solid #2196f3 !important;
  }
}

.amplify-tabs__panel--active {
  display: flex;
  justify-content: flex-end;
  .eq-index--carousel-item {
    background: #0000008f;
    text-align: center;
    width: 400px;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    right: 0;
    left: 0;
  }
}

.eq-schedule-cards {
  .amplify-card {
    flex: 1;
    flex-basis: calc(100% / 5);
    padding: 20px;
    box-shadow: 0px 2px 6px -2px #000000;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
    position: relative;
    cursor: pointer;
    min-width: auto;

    span {
      display: block;
      margin-top: 15px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }

  .nav-icon {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
}

.eq-schedule-card-container {
  width: calc(100% + 139px);
  margin: -40px -70px;
  padding: 0px 40px 0px 40px;
  height: 200px;
  background-color: #f3f6fc;
  overflow-y: hidden;

  .schedule-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    position: relative;
    top: 40px !important;

    .schedule-card-item {
      min-width: 377px;
      transition: opacity 0.5s ease-in-out;
      .custom-button {
        width: 50px !important;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}

.search-eq {
  input {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .amplify-field-group__inner-end {
    display: none;
  }

  &.search-sp {
    max-width: 694px;
    margin-left: 2px;
    input {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 24px;
      border-color: #D9E4FD;
      padding-top: 15px;
      padding-bottom: 15px;
      border-right: 0;
    }
    button {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 24px;
      border-color: #D9E4FD;
      font-size: 20px;
      padding: 3px 8px 0 2px;

      .amplify-icon {
        display: flex;
        width: 44px !important;
        height: 44px !important;
        background-color: #4365DE;
        color: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 28px;
      }
    }
  }
}
.sp-view {
  display: none;
}

.pc-view {
  display: block;
}

@media (max-width: 768px) {
  .eq-schedule-card-container {
    display: none;
  }
  .pc-view {
    display: none;
  }
  .sp-view {
    display: block;
    .eq-header-details {
      .status {
        background-color: #f0f9f3 !important;
        color: #1c8c6e !important;

        font-weight: 500px;
        font-size: 8px;
        line-height: 20px;
        letter-spacing: 0.17px;
        padding: 10px 20px;
        border-radius: 10px;
        align-content: center;
      }
      .category {
        border: 1px solid #4365de;
        border-radius: 10;
        padding: 5px 20px;
        color: #4365de;
        font-weight: 500px;
        font-size: 8px;
        line-height: 20px;
        letter-spacing: 0.17px;
        border-radius: 10px;
        align-content: center;
      }
      .name {
        padding: 5px 20px;
        width: fit-content;
        font-weight: 500px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }
  }
}

.eq-list-item {
  @media (max-width: 767px) {
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 30px;
      background-color: #4365DE;
      right: 0;
      top: -1px;
      z-index: 2;
    }
  }
}

.eq-list {
  .custom-button {
    min-width: auto;
  }
}

.eq-filter-field {
  @media (max-width: 767px) {
    justify-content: flex-start !important;
  }
}

.scrollable-pc {
  @media (min-width: 767px) {
    overflow-x: scroll;
  }
}

// @media (min-width: 767px) {
  .vertical-scroll {
    overflow: hidden;
    overflow-y: auto;
    max-height: 400px;
    display: block;

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    // table {
    //   border-collapse: collapse;
    // }

    // thead {
    //   position: sticky;
    //   top: -1px;
    //   z-index: 1;
    // }

    // tbody {
    //   overflow-y: auto;
    // }
  }
  .custom-table {
    thead {
      width: calc(100% - 16px);
      display: table;
      table-layout: fixed;

      @media (min-width: 767px) {
        position: relative;
        &.no-padding {
          width: 100%;
          position: static;
        }
        &::after {
          content: '';
          position: absolute;
          right: -16px;
          top: -1px;
          width: 16px;
          height: 100%;
          background-color: #edf0f8;
          border-top: 1px solid #0000001f;
        }
      }
    }
  }
// }

input::-webkit-date-and-time-value {
  min-height: 30px;
}

@media (max-width: 1070px) {
  .blocked-field {
    display: block !important;
    width: 100% !important;
    .amplify-flex {
      display: block !important;
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}