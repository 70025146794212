.sched-rgstr-container {
  width: 80% !important;
  .amplify-button {
    border-radius: 10px !important;
  }
  .sp-view {
    display: none !important;
  }
}
.form-actions {
  flex-direction: row;
  justify-content: end;
  margin-top: 40px;
  .amplify-button {
    width: 355px !important;
  }
}
@media only screen and (max-width: 767px) {
  .sched-rgstr-container {
    width: 100% !important;
    .pc-view {
      display: none !important;
    }
    .sp-view {
      display: block !important;
      gap: 10;
    }
  }

  .form-actions {
    flex-direction: column;
    justify-content: end;
    margin-top: 40px;
    .amplify-button {
      width: 100% !important;
    }
  }
}
